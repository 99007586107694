import {
  Anchor,
  Badge,
  ColorSchemeScript,
  Indicator,
  MantineProvider,
  Paper,
  createTheme,
} from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';
import { Notifications } from '@mantine/notifications';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import React, { FC } from 'react';
import { I18nextProvider } from 'react-i18next';
import keycloak from './auth/keycloak';
import { AuthProvider } from './auth/useAuth';
import AppRoutes from './components/layout/routes/_appRoutes';
import i18n from './i18n/i18n';

const MemoizedAppRoutes = React.memo(AppRoutes);
const theme = createTheme({
  /** Your theme override here */
  components: {
    Indicator: Indicator.extend({
      defaultProps: {
        radius: 'xs',
      },
    }),
    Badge: Badge.extend({
      defaultProps: {
        radius: 'sm',
        variant: 'light',
      },
    }),
    Paper: Paper.extend({
      defaultProps: {
        shadow: 'md',
        withBorder: true,
        p: 'md',
      },
    }),
    Anchor: Anchor.extend({
      defaultProps: {
        size: 'sm',
      },
    }),
  },
});
export const AppProviders: FC = () => (
  // const colorSchemeManager = localStorageColorSchemeManager({
  //   key: 'my-app-color-scheme',
  // });

  // useEffect(() => {
  //   document.body.classList.remove('dark-theme', 'light-theme');
  //   document.body.classList.add(`${colorScheme}-theme`);
  // }, [colorScheme]);

  <>
    <ColorSchemeScript defaultColorScheme="auto" />
    <MantineProvider defaultColorScheme="auto" theme={theme}>
      <ReactKeycloakProvider authClient={keycloak}>
        <AuthProvider>
          <Notifications autoClose={3000} position="top-right" />
          <ModalsProvider>
            <I18nextProvider i18n={i18n}>
              <MemoizedAppRoutes />
            </I18nextProvider>
          </ModalsProvider>
        </AuthProvider>
      </ReactKeycloakProvider>
    </MantineProvider>
  </>
);
