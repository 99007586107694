import axios from 'axios';
import { RegisterSubDto, SubOrgEntity } from '../types/open-api/types';

export const RegistrationService = {
    async findSubByConnectCode(code: string | undefined): Promise<SubOrgEntity> {
        if (!code) { return undefined; }
        const response = await axios.get('/common/registration/sub', {
            params: {
                code,
            },

        });
        return response.data;
    },
    async createSub(registerSubDto: RegisterSubDto): Promise<any> {
        const response = await axios.post('/common/registration/sub', registerSubDto);
        return response.data;
    },
};
