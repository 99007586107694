import { SpotlightActionData } from '@mantine/spotlight';
import {
  IconBuilding,
  IconBuildingCommunity,
  IconHammer,
  IconHome,
  IconHome2,
  IconList,
  IconUser,
} from '@tabler/icons-react';
import { NavigateFunction } from 'react-router-dom';
import { JobService, PlanService, ScheduleService } from 'sublinx-autopilot-shared';
import { SubdivisionService } from 'sublinx-autopilot-shared/src/services/SubdivisionService';
import keycloak from '../auth/keycloak';
import i18n from '../i18n/i18n';
import AdminService from './AdminService';
import UserService from './UserService';

const SpotlightService = {
  fetchSpotlightActionDatas: async (navigate: NavigateFunction): Promise<SpotlightActionData[]> => {
    const jobs = await JobService.fetchAll();

    const jobActions: SpotlightActionData[] = jobs.content!.map((job) => ({
      id: `job${job.id?.toString()}`,
      label: job.address?.street1!,
      group: i18n.t('common.jobs', { count: 2 }),
      leftSection: <IconHome />,
      onClick: () => {
        navigate(`/jobs/${job.id}`);
      },
    }));

    const trades = await AdminService.trades.fetchAll();
    const tradeActions: SpotlightActionData[] = trades.map((trade) => ({
      id: `trade${trade.id?.toString()}`,
      label: trade.name!,
      group: i18n.t('common.trades'),
      leftSection: <IconHammer />,
      onClick: () => {
        navigate(`/admin/trades/${trade.id}`);
      },
    }));
    const builders = await AdminService.builders.fetchAll();
    const builderActions: SpotlightActionData[] = builders.map((builder) => ({
      id: `builder${builder.id?.toString()}`,
      label: builder.name!,
      group: i18n.t('common.builders'),
      leftSection: <IconBuilding />,
      onClick: () => {
        navigate(`/admin/builders/${builder.id}`);
      },
    }));
    const subcontractors = await AdminService.subcontractors.fetchAll();
    const subActions: SpotlightActionData[] = subcontractors.map((sub) => ({
      id: `sub${sub.id?.toString()}`,
      label: sub.name!,
      group: i18n.t('common.subcontractors'),
      leftSection: <IconHome />,
      onClick: () => {
        navigate(`/admin/subcontractors/${sub.id}`);
      },
    }));
    return [...tradeActions, ...builderActions, ...subActions, ...jobActions];
  },
  fetchBuilderSpotlightActionDatas: async (
    navigate: NavigateFunction
  ): Promise<SpotlightActionData[]> => {
    const users = await UserService().users.fetchAll({ enabled: true });
    const userActions: SpotlightActionData[] = users.map((user) => ({
      id: `trade${user.id?.toString()}`,
      label: user.fullName!,
      group: i18n.t('common.users'),
      leftSection: <IconUser />,
      onClick: () => {
        navigate(`/users/${user.id}`);
      },
    }));
    const schedules = await ScheduleService.fetchAll();
    const scheduleActions: SpotlightActionData[] = schedules.map((schedule) => ({
      id: `schedule${schedule.id?.toString()}`,
      label: schedule.name!,
      group: i18n.t('common.schedule', { count: 2 }),
      leftSection: <IconList />,
      onClick: () => {
        navigate(`/schedules/${schedule.id}`);
      },
    }));
    const subdivisions = await SubdivisionService.fetchAll();
    const subdivisionActions: SpotlightActionData[] = subdivisions.map((subdivision) => ({
      id: `subdivision${subdivision.id?.toString()}`,
      label: subdivision.name!,
      group: i18n.t('common.subdivision', { count: 2 }),
      leftSection: <IconBuildingCommunity />,
      onClick: () => {
        navigate(`/subdivisions/${subdivision.id}`);
      },
    }));
    const plans = await PlanService.fetchAll();
    const planActions: SpotlightActionData[] = plans.map((plan) => ({
      id: `plan${plan.id?.toString()}`,
      label: plan.name!,
      group: i18n.t('common.plan', { count: 2 }),
      leftSection: <IconHome2 />,
      onClick: () => {
        navigate(`/plans/${plan.id}`);
      },
    }));
    const jobs = await JobService.fetchAll();

    const jobActions: SpotlightActionData[] = jobs.content!.map((job) => ({
      id: `job${job.id?.toString()}`,
      label: job.address?.street1!,
      group: i18n.t('common.jobs', { count: 2 }),
      leftSection: <IconHome />,
      onClick: () => {
        navigate(`/jobs/${job.id}`);
      },
    }));
    if (keycloak.tokenParsed?.realm_access?.roles.includes('SUBLINX_ADMIN')) {
      const adminActions = await SpotlightService.fetchSpotlightActionDatas(navigate);
      return [
        ...userActions,
        ...scheduleActions,
        ...subdivisionActions,
        ...planActions,
        ...jobActions,
        ...adminActions,
      ];
    }
    return [
      ...userActions,
      ...scheduleActions,
      ...subdivisionActions,
      ...planActions,
      ...jobActions,
    ];
  },
};

export default SpotlightService;
