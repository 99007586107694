import { SimpleGrid, Stack, TextInput, Textarea, Title } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { FC } from 'react';
import * as Yup from 'yup';

interface ContactFormComponentsProps {
  form: UseFormReturnType<any>;
  isLoading: boolean;
  title?: string;
  required?: boolean;
  disabled?: boolean;
  hiddenInputs?: string[];
}
export const contactFormFields = {
  fullName: 'fullName',
  firstName: 'firstName',
  lastName: 'lastName',
  email: 'email',
  officePhone: 'officePhone',
  mobilePhone: 'mobilePhone',
  notes: 'notes',
};
export const contactShape = Yup.object().shape({
  fullName: Yup.string(),
  firstName: Yup.string(),
  lastName: Yup.string(),
  email: Yup.string().email(),
  officePhone: Yup.string(),
  mobilePhone: Yup.string(),
  notes: Yup.string(),
});

export const ContactFormComponents: FC<ContactFormComponentsProps> = ({
  title,
  form,
  isLoading,
  required,
  disabled = false,
  hiddenInputs = [],
}) => (
  <>
    <Title order={5}>{title || 'Primary Contact'}</Title>
    <Stack gap="sm">
      <SimpleGrid mt={0} cols={{ base: 2, sm: 1, md: 2 }}>
        <TextInput
          data-testid="first-name-input"
          disabled={isLoading || disabled}
          required={required}
          label="First Name"
          placeholder="Enter first name"
          {...form.getInputProps('contact.firstName')}
        />
        <TextInput
          data-testid="last-name-input"
          disabled={isLoading || disabled}
          required={required}
          label="Last Name"
          placeholder="Enter last name"
          {...form.getInputProps('contact.lastName')}
        />
      </SimpleGrid>
      <SimpleGrid mt={0} cols={{ base: 3, sm: 1, md: 3 }}>
        <TextInput
          data-testid="email-input"
          disabled={isLoading || disabled}
          label="Email"
          type="email"
          placeholder="Enter email"
          {...form.getInputProps('contact.email')}
        />
        <TextInput
          data-testid="office-phone-input"
          disabled={isLoading || disabled}
          required={required}
          type="phone"
          label="Office Phone"
          placeholder="Enter office phone"
          {...form.getInputProps('contact.officePhone')}
        />
        <TextInput
          data-testid="mobile-phone-input"
          disabled={isLoading || disabled}
          label="Mobile Phone"
          type="phone"
          placeholder="Enter mobile phone"
          {...form.getInputProps('contact.mobilePhone')}
        />
      </SimpleGrid>
      {!hiddenInputs.includes(contactFormFields.notes) && (
        <Textarea
          data-testid="notes-input"
          disabled={isLoading || disabled}
          label="Notes"
          placeholder="Enter notes"
          {...form.getInputProps('contact.notes')}
        />
      )}
    </Stack>
  </>
);
