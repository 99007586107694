import axios from 'axios';
import {
  BuilderOrgEntity,
  BuilderOrgPatch,
  SubOrgEntity,
  SubOrgPatch,
  TradeEntity,
  TradePatch,
  TradeTaskEntity,
  TradeTaskPatch,
} from 'sublinx-autopilot-shared';
import { OptionalId } from '../utils/types';

const AdminService = {
  trades: {
    async fetchAll(): Promise<TradeEntity[]> {
      const response = await axios.get('/common/admin/trades');
      return response.data;
    },
    async fetchById(id: number | string): Promise<TradeEntity> {
      const response = await axios.get(`/common/admin/trades/${id}`);
      return response.data;
    },
    async save(trade: OptionalId<TradePatch>): Promise<TradeEntity> {
      let response;
      if (trade.id) {
        response = await axios.patch(`/common/admin/trades/${trade.id}`, trade);
      } else {
        response = await axios.post('/common/admin/trades', trade);
      }

      return response.data;
    },
    async delete(id: number | string) {
      const response = await axios.delete(`/common/admin/trades/${id}`);
      return response.data;
    },
    tasks: {
      async fetchAllByTrade(id: number | string): Promise<TradeTaskEntity[]> {
        const response = await axios.get(`/common/admin/trades/${id}/tasks`);
        return response.data;
      },
      async fetchAll(): Promise<TradeTaskEntity[]> {
        const response = await axios.get('/common/admin/trades/tasks');
        return response.data;
      },
      async save(trade: TradeEntity, task: OptionalId<TradeTaskPatch>): Promise<TradeTaskEntity> {
        let response;
        if (task.id) {
          response = await axios.patch(`/common/admin/trades/tasks/${task.id}`, task);
        } else {
          response = await axios.post(`/common/admin/trades/${trade.id}/tasks`, task);
        }

        return response.data;
      },
      async saveRank(rankedTasks: number[]): Promise<TradeTaskEntity> {
        const response = await axios.patch('/common/admin/trades/tasks/rank', rankedTasks);
        return response.data;
      },
      async delete(id: number | string) {
        const response = await axios.delete(`/common/admin/trades/tasks/${id}`);
        return response.data;
      },
    },
  },
  builders: {
    async fetchAll(): Promise<BuilderOrgEntity[]> {
      const response = await axios.get('/common/admin/builders');
      return response.data;
    },
    async fetchById(id: number | string): Promise<BuilderOrgEntity> {
      const response = await axios.get(`/common/admin/builders/${id}`);
      return response.data;
    },
    async save(builder: OptionalId<BuilderOrgPatch>): Promise<BuilderOrgEntity> {
      let response;
      if (builder.id) {
        response = await axios.patch(`/common/admin/builders/${builder.id}`, builder);
      } else {
        response = await axios.post('/common/admin/builders', builder);
      }

      return response.data;
    },
    async delete(id: number | string) {
      const response = await axios.delete(`/common/admin/builders/${id}`);
      return response.data;
    },
  },
  subcontractors: {
    async fetchAll(): Promise<SubOrgEntity[]> {
      const response = await axios.get('/common/admin/subs');
      return response.data;
    },
    async fetchById(id: number | string): Promise<SubOrgEntity> {
      const response = await axios.get(`/common/admin/subs/${id}`);
      return response.data;
    },
    async save(subcontractor: OptionalId<SubOrgPatch>): Promise<SubOrgEntity> {
      let response;
      if (subcontractor.id) {
        response = await axios.patch(`/common/admin/subs/${subcontractor.id}`, subcontractor);
      } else {
        response = await axios.post('/common/admin/subs', subcontractor);
      }

      return response.data;
    },
    async delete(id: number | string) {
      const response = await axios.delete(`/common/admin/subs/${id}`);
      return response.data;
    },
  },
};
export default AdminService;
