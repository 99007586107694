import {
  Button,
  Card,
  Group,
  LoadingOverlay,
  NativeSelect,
  PasswordInput,
  SimpleGrid,
  Stack,
  Text,
  TextInput,
} from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { hideNotification, showNotification, updateNotification } from '@mantine/notifications';
import { IconCheck } from '@tabler/icons-react';
import { useQuery } from '@tanstack/react-query';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { RegistrationService } from 'sublinx-autopilot-shared/src/services/RegistrationService';
import * as Yup from 'yup';
import {
  ContactFormComponents,
  contactFormFields,
} from '../../../components/form/ContactFormComponents';
import { StreetAddressFormComponents } from '../../../components/form/StreetAddressFormComponents';

const schema = Yup.object().shape({
  name: Yup.string().required('Company Name is required'),
  tradeId: Yup.string().required('Trade is required'),
  contact: Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Email is required'),
    password: Yup.string().required('Password is required'),
    confirmPassword: Yup.string()
      .required('Confirmation password is required')
      .oneOf([Yup.ref('password')], 'Passwords must match'),
  }),
});

export const SubcontractorRegistration: FC = () => {
  const [searchParams] = useSearchParams();
  const subQuery = useQuery({
    queryKey: ['sub', searchParams.get('code')],
    queryFn: () => RegistrationService.findSubByConnectCode(searchParams.get('code') || undefined),
    enabled: !!searchParams.get('code'),
  });

  const [isLoading, setIsLoading] = React.useState(false);

  const navigate = useNavigate();
  const { t } = useTranslation();

  const form = useForm({
    validate: yupResolver(schema),
    validateInputOnChange: true,
    initialValues: {
      name: subQuery?.data?.name || '',
      connectCode: searchParams.get('code') || '',
      tradeId: subQuery?.data?.trade?.id || '',
      address: {
        street1: subQuery?.data?.address?.street1 || '',
        street2: subQuery?.data?.address?.street2 || '',
        city: subQuery?.data?.address?.city || '',
        state: subQuery?.data?.address?.state || '',
        postalCode: subQuery?.data?.address?.postalCode || '',
      },
      contact: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        notes: '',
        password: '',
        confirmPassword: '',
      },
    },
  });

  useEffect(() => {
    form.setValues({
      ...form.values,
      name: subQuery?.data?.name || '',
      tradeId: subQuery?.data?.trade?.id || '',
    });
  }, [subQuery.data]);

  //   const { data: trades } = useQuery({
  //     queryKey: ['trades'],
  //     queryFn: AdminService.trades.fetchAll,
  //   });
  const handleSubmit = async (values: typeof form.values) => {
    setIsLoading(true);
    form.resetDirty();
    showNotification({
      id: 'manageSubNotification',
      autoClose: false,
      loading: true,
      title: 'Saving',
      message: 'Please wait...',
    });

    RegistrationService.createSub({
      ...values,
      subOrgEntity: {
        ...values,
        tradeId: Number(values.tradeId),
        address: {
          ...values.address,
        },
        contact: {
          ...values.contact,
        },
      },
      subUserEntity: {
        ...values.contact,
        username: values?.contact.email,
        givenName: values?.contact.firstName,
        familyName: values?.contact.lastName,
        enabled: true,
        email: values?.contact.email,
        password: values?.contact.password,
      },
    })
      .then(() => {
        updateNotification({
          id: 'manageSubNotification',
          loading: false,
          title: 'Save Successful',
          message: 'Sub saved successfully',
          icon: <IconCheck />,
          color: 'green',
        });
        // if (sub?.id) {
        //   refetchSub();
        // } else {
        //   navigate(`/admin/subcontractors/${updatedSub?.id}`);
        // }
        navigate('/');
      })
      .catch(() => {
        // updateNotification({
        //   id: 'manageSubNotification',
        //   loading: false,
        //   title: 'Error while saving',
        //   message: e.message,
        //   icon: <IconExclamationMark />,
        //   color: 'red',
        // });
        hideNotification('manageSubNotification');
        setIsLoading(false);
      })
      .finally(async () => {
        setIsLoading(false);
      });
  };

  //   if (!trades) {
  //     return <SuspenseLoader />;
  //   }

  return (
    <form onSubmit={form.onSubmit((values) => handleSubmit(values))} noValidate autoComplete="off">
      <Card>
        <Card.Section withBorder inheritPadding py="md">
          <Text fw="bold">Subcontractor Registration</Text>
        </Card.Section>
        <Card.Section inheritPadding mb="md" py="md">
          <LoadingOverlay
            loaderProps={{ variant: 'bars' }}
            visible={isLoading}
            overlayProps={{
              blur: 5,
              opacity: 0.5,
            }}
          />

          <Stack>
            <SimpleGrid cols={2}>
              <TextInput
                data-testid="name-input"
                autoFocus
                disabled={isLoading}
                required
                label="Company Name"
                placeholder="Enter Company name"
                {...form.getInputProps('name')}
              />
              <NativeSelect
                data={
                  subQuery.data?.trade?.name
                    ? [
                        {
                          label: subQuery.data?.trade?.name!,
                          value: subQuery.data?.trade?.id?.toString()!,
                        },
                      ]
                    : []
                }
                value={subQuery.data?.trade?.id || ''}
                data-testid="trade-select"
                label="Trade"
                required
                disabled={isLoading || subQuery.data?.trade?.name !== undefined}
                {...form.getInputProps('tradeId')}
              />
            </SimpleGrid>
            <StreetAddressFormComponents form={form} required={false} isLoading={isLoading} />
            <ContactFormComponents
              title="Primary User"
              hiddenInputs={[contactFormFields.notes]}
              form={form}
              required={false}
              isLoading={isLoading}
            />
            <SimpleGrid cols={2}>
              <PasswordInput
                data-testid="password-input"
                label="Password"
                placeholder="Password"
                {...form.getInputProps('contact.password')}
              />
              <PasswordInput
                data-testid="confirm-password-input"
                label="Confirm Password"
                placeholder="Confirm Password"
                {...form.getInputProps('contact.confirmPassword')}
              />
            </SimpleGrid>
          </Stack>
        </Card.Section>
        <Card.Section withBorder inheritPadding py="sm">
          <Group justify="space-between">
            <Button
              component={Link}
              to="/admin/subcontractors"
              disabled={isLoading}
              type="button"
              variant="subtle"
            >
              {t('common.cancel')}
            </Button>
            <Button data-testid="submit-button" disabled={isLoading} type="submit">
              {t('common.save')}
            </Button>
          </Group>
        </Card.Section>
      </Card>
    </form>
  );
};
