import {
  Button,
  Divider,
  Grid,
  Group,
  Image,
  List,
  SimpleGrid,
  Text,
  ThemeIcon,
  Title,
} from '@mantine/core';
import { useKeycloak } from '@react-keycloak/web';
import {
  IconCheck,
  IconCircleDotted,
  IconFileCode,
  IconFlame,
  IconReceiptOff,
} from '@tabler/icons-react';
import { useQueryClient } from '@tanstack/react-query';
import { FC } from 'react';
import { useLocation } from 'react-router-dom';

import image from '../../../assets/images/landing/image.svg';
import classes from './Login.module.css';

const BASE_URL = import.meta.env.VITE_BASE_URL;

const features = [
  {
    icon: IconReceiptOff,
    title: 'TODO: Title 1',
    description: 'TODO: Description',
  },
  {
    icon: IconFileCode,
    title: 'TODO: Title 2',
    description: 'TODO: Description',
  },
  {
    icon: IconCircleDotted,
    title: 'TODO: Title 3',
    description: 'TODO: Description',
  },
  {
    icon: IconFlame,
    title: 'TODO: Title 4',
    description: 'TODO: Description',
  },
];

export function FeaturesTitle() {
  const items = features.map((feature) => (
    <div key={feature.title}>
      <ThemeIcon
        size={44}
        radius="md"
        variant="gradient"
        gradient={{ deg: 133, from: 'blue', to: 'cyan' }}
      >
        <feature.icon size={26} />
      </ThemeIcon>
      <Text size="lg" mt="sm" fw={500}>
        {feature.title}
      </Text>
      <Text c="dimmed" size="sm">
        {feature.description}
      </Text>
    </div>
  ));

  return (
    <div>
      <Divider my={50} />
      <Grid>
        <Grid.Col span={{ md: 7 }}>
          <SimpleGrid spacing={30} cols={{ xs: 1 }}>
            {items}
          </SimpleGrid>
        </Grid.Col>
        <Grid.Col span={{ md: 5 }}>
          <Title className={classes.featureTitle} order={2}>
            Lorem ipsum dolor, sit amet consectetur adipisicing elit.
          </Title>
          <Text c="dimmed">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cupiditate nesciunt quis eos
            nam soluta quaerat laboriosam. Dicta in eos, eaque porro, maxime odio, harum assumenda
            aperiam deleniti autem tenetur consequatur.
          </Text>
        </Grid.Col>
      </Grid>
    </div>
  );
}
interface stateType {
  from: { pathname: string };
}
export function HeroBullets() {
  const { keycloak } = useKeycloak();
  const location = useLocation();
  const queryClient = useQueryClient();
  const { from } = location.state ? (location.state as stateType) : { from: { pathname: '/' } };
  return (
    <div>
      <div className={classes.inner}>
        <div className={classes.content}>
          {/* <Title className={classes.title}>
            A <span className={classes.highlight}>better</span> Scheduling and Time Tracking System
          </Title>
          <Text c="dimmed" mt="md">
            Finally a scheduling system that actually works – Sublinx Autopilot uses state of the
            art technology to provide a better experience for you and your team.
          </Text>

          <List
            mt={30}
            spacing="sm"
            size="sm"
            icon={
              <ThemeIcon size={20} radius="xl">
                <IconCheck size={12} />
              </ThemeIcon>
            }
          >
            <List.Item>
              <b>GPS Time Tracking</b> – track time based on your employees gps location
            </List.Item>
            <List.Item>
              <b>Auto Scheduling</b> – your workorders or scheduled when the build sends over the
              purchase order
            </List.Item>
            <List.Item>
              <b>Informative Reporting</b> – get a glimpse of everything going on in seconds
            </List.Item>
          </List> */}

          <Group mt={30}>
            <Button
              id="cta-login-button"
              size="md"
              className={classes.control}
              onClick={() => {
                queryClient.clear();
                keycloak.login({ redirectUri: `${BASE_URL}${from?.pathname}` || '/' });
              }}
            >
              Login to Sublinx Autopilot
            </Button>
            {/* <Button variant="default" radius="xl" size="md" className={classes.control}>
              Schedule Demo
            </Button> */}
          </Group>
        </div>
        {/* <Image src={image} className={classes.image} /> */}
      </div>
      {/* <FeaturesTitle /> */}
    </div>
  );
}

export const Login: FC = () => (
  <>
    <HeroBullets />
  </>
);
