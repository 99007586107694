import axios from 'axios';
import { BuildScheduleDto, BuildScheduleEntity, BuildSchedulePatch } from '../types/open-api/types';
import { OptionalId } from '../types/types';

export const ScheduleService = {
  async fetchAll(): Promise<BuildScheduleEntity[]> {
    const response = await axios.get('/common/schedules');
    return response.data;
  },
  async fetchById(id: number | string): Promise<BuildScheduleDto> {
    const response = await axios.get(`/common/schedules/${id}`);
    return response.data;
  },
  async delete(id: number | string) {
    const response = await axios.delete(`/common/schedules/${id}`);
    return response.data;
  },
  async save(buildSchedule: OptionalId<BuildSchedulePatch>): Promise<BuildScheduleEntity> {
    let response;
    if (buildSchedule.id) {
      response = await axios.patch(`/common/schedules/${buildSchedule.id}`, buildSchedule);
    } else {
      response = await axios.post('/common/schedules/', buildSchedule);
    }

    return response.data;
  },
  tasks: {
    async saveTask(
      scheduleId: number | string,
      buildScheduleStageId: number | string,
      days: number | string | undefined,
      tradeTaskId: number | string,
      conditionTaskIds: number[] | string[] | undefined
    ): Promise<BuildScheduleEntity> {
      const response = await axios.post(`/common/schedules/${scheduleId}/tasks`, {
        days,
        tradeTaskId,
        buildScheduleStageId,
        conditionTaskIds,
      });
      return response.data;
    },
    async updateTask(
      scheduleTaskId: number | string,
      buildScheduleStageId: number | string,
      days: number | string | undefined,
      conditionTaskIds: number[] | string[] | undefined | null
    ): Promise<BuildScheduleEntity> {
      const response = await axios.patch(`/common/schedules/tasks/${scheduleTaskId}`, {
        buildScheduleStageId,
        days,
        conditionTaskIds: conditionTaskIds?.length ? conditionTaskIds : null,
      });
      return response.data;
    },
    async delete(id: number | string) {
      const response = await axios.delete(`/common/schedules/tasks/${id}`);
      return response.data;
    },
  },
};
