import axios from 'axios';
import { JobGist, PlanEntity, SubOrgDto, SubdivisionEntity, SubdivisionPatch } from '../types/open-api/types';
import { OptionalId } from '../types/custom-types';

export const SubdivisionService = {
  async save(subdivision: OptionalId<SubdivisionPatch>): Promise<SubdivisionEntity> {
    if (subdivision.id) {
      const response = await axios.patch(`/common/subdivisions/${subdivision.id}`, subdivision);
      return response.data;
    }
    const response = await axios.post('/common/subdivisions', subdivision);
    return response.data;
  },
  async fetchById(id: number | string): Promise<SubdivisionEntity> {
    const response = await axios.get(`/common/subdivisions/${id}`);
    return response.data;
  },
  async fetchAll(): Promise<SubdivisionEntity[]> {
    const response = await axios.get('/common/subdivisions');
    return response.data;
  },
  async fetchJobsBySubdivisionId(id: number | string): Promise<JobGist[]> {
    const response = await axios.get(`/common/subdivisions/${id}/jobs/gists`);
    return response.data;
  },
  async addUserToSubdivision(subdivisionId: number | string, userId: number | string): Promise<void> {
    const response = await axios.post(`/common/subdivisions/${subdivisionId}/users/${userId}`);
    return response.data;
  },
  async removeUserFromSubdivision(subdivisionId: number | string, userId: number | string): Promise<void> {
    const response = await axios.delete(`/common/subdivisions/${subdivisionId}/users/${userId}`);
    return response.data;
  },
  async fetchPlansBySubdivisionId(id: number | string): Promise<PlanEntity[]> {
    const response = await axios.get(`/common/subdivisions/${id}/plans`);
    return response.data;
  },
  async addPlanToSubdivision(subdivisionId: number | string, planId: number | string): Promise<void> {
    const response = await axios.post(`/common/subdivisions/${subdivisionId}/plans/${planId}`);
    return response.data;
  },
  async removePlanFromSubdivision(subdivisionId: number | string, planId: number | string): Promise<void> {
    const response = await axios.delete(`/common/subdivisions/${subdivisionId}/plans/${planId}`);
    return response.data;
  },
  orgs: {
    async fetchAllBySubdivision(subdivisionId: number | string): Promise<SubOrgDto[]> {
      const response = await axios.get(`/common/subdivisions/${subdivisionId}/orgs`);
      return response.data;
    },
    async fetchAvailableBySubdivision(subdivisionId: number | string): Promise<SubOrgDto[]> {
      const response = await axios.get(`/common/subdivisions/${subdivisionId}/available-orgs`);
      return response.data;
    },
    async save(subdivisionId: number | string, subOrgId: number | string): Promise<SubdivisionEntity> {
      const response = await axios.post(`/common/subdivisions/${subdivisionId}/orgs/${subOrgId}`);

      return response.data;
    },
    async delete(subdivisionId: number | string, subOrgId: number | string) {
      const response = await axios.delete(`/common/subdivisions/${subdivisionId}/orgs/${subOrgId}`);
      return response.data;
    },
  },
};
