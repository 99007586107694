import { AppShell, Center, Container, useMantineColorScheme, useMantineTheme } from '@mantine/core';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import { Spotlight } from '@mantine/spotlight';
import { IconSearch } from '@tabler/icons-react';
import { useQuery } from '@tanstack/react-query';
import React, { FC, useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import SpotlightService from '../../services/SpotlightService';
import { HeaderBar } from './HeaderBar';
import { NavbarNested } from './NavbarNested';
import { SuspenseLoader } from './SuspenseLoader';

interface SuspensedViewProps {
  children?: React.ReactNode;
}
const SuspensedView: FC<SuspensedViewProps> = () => (
  // useEffect(() => {
  //   startNavigationProgress();
  //   return () => {
  //     resetNavigationProgress();
  //   };
  // });

  <Center>
    <SuspenseLoader />
  </Center>
);
interface LayoutProps {
  children?: React.ReactNode;
}

export const Layout: FC<LayoutProps> = () => {
  const theme = useMantineTheme();
  const { toggleColorScheme } = useMantineColorScheme();
  const [mobileOpened, mobileHandlers] = useDisclosure(false);
  const [desktopCollapsed, desktopHandlers] = useDisclosure(false);
  const [spotlightEnabled, setSpotlightEnabled] = React.useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width: 48em)');
  const { data: spotlightData, isFetching: isSpotlightFetching } = useQuery({
    queryKey: ['spotlightData'],
    queryFn: () => SpotlightService.fetchSpotlightActionDatas(navigate),
    refetchOnWindowFocus: false,
    enabled: spotlightEnabled,
  });
  useEffect(() => {
    mobileHandlers.close();
  }, [location]);

  return (
    <>
      <Spotlight
        actions={spotlightData && !isSpotlightFetching ? spotlightData || [] : []}
        searchProps={{
          leftSection: <IconSearch size={18} />,
          placeholder: 'Search...',
        }}
        nothingFound="No results found"
        highlightQuery
        limit={10}
        onSpotlightOpen={() => {
          setSpotlightEnabled(true);
        }}
      />
      <AppShell
        layout="alt"
        // navbarOffsetBreakpoint="md"
        padding="md"
        header={{ height: 70 }}
        navbar={{
          width: !isMobile && !desktopCollapsed ? { base: 200, md: 275, lg: 275 } : { base: 80 },
          breakpoint: 'sm',
          collapsed: { mobile: !mobileOpened },
        }}
      >
        <HeaderBar
          desktopHandlers={desktopHandlers}
          desktopCollapsed={desktopCollapsed}
          toggleColorScheme={toggleColorScheme}
          opened={mobileOpened}
          handlers={mobileHandlers}
          theme={theme}
        />
        <NavbarNested
          isMobile={isMobile || false}
          handlers={mobileHandlers}
          opened={mobileOpened}
          desktopHandlers={desktopHandlers}
          desktopCollapsed={desktopCollapsed}
        />
        <AppShell.Main>
          <Container
            p={0}
            size="xl"
            // style={{
            //   '@media (max-width: 755px)': {
            //     padding: theme.spacing.xs,
            //   },
            //   '@media (min-width: 755px)': {
            //     padding: theme.spacing.lg,
            //     paddingTop: theme.spacing.sm,
            //   },
            // }}
          >
            <React.Suspense fallback={<SuspensedView />}>
              <Outlet />
            </React.Suspense>
          </Container>
        </AppShell.Main>
      </AppShell>
    </>
  );
};
