import axios from 'axios';
import {
  FileEntity,
  PageJobEntity,
  PagePlanEntity,
  PlanEntity,
  PlanGist,
  PlanPatch,
  SubPlanDto,
  SubTradeTaskPatch,
  SubdivisionEntity,
  SubdivisionPatch,
} from '../types/open-api/types';
import { OptionalId } from '../types/types';

export const PlanService = {
  async fetchAll(): Promise<PlanEntity[]> {
    const response = await axios.get('/common/plans');
    return response.data;
  },
  async fetchAllGists(): Promise<PlanGist[]> {
    const response = await axios.get('/common/plans/gists');
    return response.data;
  },
  async fetchAllPaged(
    pageSize: number,
    pageNumber: number,
    sortColumn: string,
    sortDirection: string
  ): Promise<PagePlanEntity> {
    const response = await axios.get('/common/plans/paged', {
      params: {
        page: pageNumber,
        size: pageSize,
        sort: [sortColumn, sortDirection].join(','),
      },
    });
    return response.data;
  },
  async fetchAllGistsBySubdivisionId(id: number | string): Promise<PlanGist[]> {
    const response = await axios.get(`/common/subdivisions/${id}/plans/gists`);
    return response.data;
  },
  async fetchAllJobsPaged(
    id: number | string,
    pageSize: number,
    pageNumber: number,
    sortColumn: string,
    sortDirection: string
  ): Promise<PageJobEntity> {
    const response = await axios.get(`/common/plans/${id}/jobs`, {
      params: {
        page: pageNumber,
        size: pageSize,
        sort: [sortColumn, sortDirection].join(','),
      },
    });
    return response.data;
  },
  async fetchById(id: number | string): Promise<PlanEntity> {
    const response = await axios.get(`/common/plans/${id}`);
    return response.data;
  },
  async save(plan: OptionalId<PlanPatch>): Promise<PlanEntity> {
    let response;
    if (plan.id) {
      response = await axios.patch(`/common/plans/${plan.id}`, plan);
    } else {
      response = await axios.post('/common/plans', plan);
    }

    return response.data;
  },
  async delete(id: number | string) {
    const response = await axios.delete(`/common/plans/${id}`);
    return response.data;
  },
  subcontractors: {
    async findCustomTaskPlans(): Promise<SubPlanDto[]> {
      const response = await axios.get('/common/plans/sub-org-plans');
      return response.data;
    },
    async saveCustomTaskPlan(
      taskId: number,
      customTaskPlan: SubTradeTaskPatch | any,
      planId?: string
    ): Promise<void> {
      const response = await axios.patch(
        `/common/plans/sub-org-plans/${planId}/tasks/${taskId}`,
        customTaskPlan
      );
      return response.data;
    },
  },
  subdivisions: {
    async fetchAllByPlan(planId: number | string): Promise<SubdivisionEntity[]> {
      const response = await axios.get(`/common/plans/${planId}/subdivisions`);
      return response.data;
    },
    async fetchById(id: number | string): Promise<SubdivisionEntity> {
      const response = await axios.get(`/common/plans/${id}/subdivisions`);
      return response.data;
    },
    async save(
      planId: number | string,
      subdivision: OptionalId<SubdivisionPatch>
    ): Promise<SubdivisionEntity> {
      let response;
      if (subdivision.id) {
        response = await axios.patch(
          `/common/plans/${planId}/subdivisions/${subdivision.id}`,
          subdivision
        );
      } else {
        response = await axios.post(`/common/plans/${planId}/subdivisions/`, subdivision);
      }

      return response.data;
    },
  },
  files: {
    async fetchAllPlanFiles(id: number | string): Promise<FileEntity[]> {
      const response = await axios.get(`/common/plans/${id}/files`);
      return response.data;
    },
    async uploadFile(planId: number | string, multipartFile: File): Promise<FileEntity> {
      const response = await axios.post(
        `/common/plans/${planId}/files`,
        {
          multipartFile,
        },
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      return response.data;
    },
  },
};
